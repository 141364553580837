.career_sections {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  h2:after {
    content: attr(data-text);
    color: red;
    transform: translateX(-100%);
    position: absolute;
  }

  .box-container {
    margin: -1rem;
    .box {
      height: 100%;
      background: #f9fafb;
      // flex: 33.333333%;
      // margin: 1rem;
      // max-width: 22.5rem;
      padding: 1.5rem;

      // @media only screen and (max-width: $widescreen) and (min-width: $desktop) {
      //   max-width: 21.5rem;
      // }
      // @media only screen and (max-width: $desktop) and (min-width: $custom) {
      //   max-width: 18.7rem;
      // }
      // @media only screen and (max-width: $tablet) and (min-width: $mobile) {
      //   max-width: 16.8rem;
      // }
      @media only screen and (max-width: $mobile) {
        align-self: center;
        width: 22.5rem;
        max-width: 100%;

        .is-cp-body-text-1 {
          text-align: center;
        }
      }
      @media only screen and (max-width: $smallMobile) {
        max-width: -webkit-fill-available;
      }
    }

    @media only screen and (max-width: 991px) {
      align-items: center;
    }
  }

  .Career_Heading {
    padding-bottom: 2rem;
  }

  img {
    width: fit-content;
  }

  a {
    text-decoration: none;
  }

  a {
    background-color: inherit;
  }

  @media only screen and (max-width: $mobile) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
