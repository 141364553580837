.wrapper {
  background-color: #d5e9f6;
  .ghostImage {
    padding-top: 15px;
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }
  .linktoHome {
    text-align: center;
    text-decoration: none;
    color: #5f749c;
    font-size: large;
    line-height: 5rem;
  }
  .linktoHome:hover {
    font-weight: 700;
  }
  .headerContent {
    text-align: center;
    color: #5f749c;
    line-height: 5rem;
  }
  .errorContent {
    display: flex;
    vertical-align: middle;
    margin-left: 20%;
    margin-right: 20%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #5f749c;
    line-height: 3rem;
  }
}
