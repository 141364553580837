.jobDescHeading {
  padding: 4rem 0;
  .designationHeading {
    color: $accent;
  }
  .JDJumbotron {
    img {
      height: 30px;
    }
  }
  .JDJumbotronImage {
    text-align: end;

    img {
      width: 100%;
    }
  }
  @media only screen and (max-width: $tablet) {
    padding: 2.5rem 2rem;
  }
  @media only screen and (max-width: $mobile) {
    padding: 2.5rem 0;
  }
}
