.main-title {
  font-size: 2.5rem;
}
.main-subtitle {
  font-size: 1.2rem;
}
.button {
  button {
    width: 20rem
  }
}

@media only screen and (max-width: 768px) {
  .main-title {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 500px) {
  .main-title {
    font-size: 1.2rem;
  }
  .main-subtitle {
    font-size: 0.98rem;
  }
  .mobile-margin-1 {
    margin-bottom: 6rem !important;
  }
  .button {
    button {
      width: 100%
    }
  }
} 