.JobDescriptionPage {
  .nav-link {
    padding: 2rem;
    @media only screen and (max-width: $tablet) {
      padding: 1rem 1.25rem;
    }
  }

  .nav-tabs {
    border-bottom: 0;
  }
  .nav-tabs .nav-link.active {
    border-color: $transparent $transparent $accent;
    color: $accent;
  }

  .nav-tabs .nav-link:hover {
    border-color: $transparent $transparent $accent;
  }

  .WorkHereSection {
    li {
      // line-height: 2rem;
    }
    .jobDescSection {
      padding-inline-start: 0;

      ul {
        padding-inline-start: 3.125rem;
        li {
          list-style-type: disc;
        }
      }
    }
  }
}
