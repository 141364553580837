.openPositionCard {
  margin-bottom: 2.5rem;
  @extend .is-cp-border;
  .experience {
    @media only screen and (max-width: calc($tablet - 1px)) {
      margin-top: 2.25rem;
      margin-bottom: 2.25rem;
    }
  }

  .openPositionCardHeading {
    outline: 0.031rem solid $cp-new-primary;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .is-cp-primary-small-button {
    margin-left: 1.5rem;
    @media only screen and (max-width: $smallMobile) {
      display: flex;
      margin-top: 1rem;
      margin-left: 0;
      margin-inline: auto;
    }
  }

  .is-cp-secondary-small-button {
    @media only screen and (max-width: $smallMobile) {
      display: flex;
      margin-inline: auto;
    }
  }
  @media only screen and (max-width: $smallMobile) {
    margin-bottom: 2rem;
  }

  .ViewMore {
    border-bottom: 1px solid;
    cursor: pointer;
    white-space: nowrap;
    color: $accent;
  }
}
