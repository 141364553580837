.careerHeading {
  padding: 3.625rem 0;
  .JoinOurTeam {
    padding-bottom: 1rem;
    @media only screen and (max-width: $mobile) {
      padding-bottom: 0.75rem;
    }
  }

  @media only screen and (max-width: $mobile) {
    padding: 2.5rem 0;
    .is-cp-primary-button {
      width: fit-content;
    }
  }

  .careerPageHeading {
    padding-bottom: 2rem;
    text-transform: uppercase;
    @media only screen and (max-width: $mobile) {
      padding-bottom: 0.75rem;
    }
  }

  .CareerHeadingContent {
    padding-bottom: 4rem;
    @media only screen and (max-width: $mobile) {
      padding-bottom: 0.75rem;
    }
  }
}
