.rightBackImage {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-image: url("../../../assets//images/banner-background.svg");
}
.bannerLogo {
  width: 100%;
  height: 645px;
}
.bannerSubLogo {
  width: 60px;
  display: block;
}
.headerTitle {
  font-size: 2.5rem;
  font-weight: bold;
}
// .bannerSubTitle {
//     h5 {
//         font-size: 0.98rem;
//         font-weight: 400;
//         color: #747474;
//         padding: 12px 0;
//         margin-bottom: 30px;
//     }
// }
button:focus {
  outline: none;
}
@media only screen and (max-width: 1400px) {
  .bannerLogo {
    height: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .headerTitle {
    font-size: 2.8rem;
    font-weight: bold;
  }
}
@media only screen and (max-width: 768px) {
  .bannerLogo {
    width: 50%;
    height: 100%;
  }
  .headerTitle {
    font-size: 2.1rem;
    font-weight: bold;
  }
  .bannerSubLogo {
    margin: auto;
  }
}
