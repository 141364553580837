.rightBackImage {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-image: url("../../../assets//images/banner-background.svg");
}
.headerLogo {
  width: 80%;
  height: 100%;
}
.headerTitle {
  font-size: 3.4rem;
  font-weight: bold;
}
button:focus {
  outline: none;
}
@media only screen and (max-width: 1200px) {
  .headerTitle {
    font-size: 2.8rem;
    font-weight: bold;
  }
}
@media only screen and (max-width: 768px) {
  .headerLogo {
    width: 50%;
    height: 100%;
  }
  .headerTitle {
    font-size: 2.1rem;
    font-weight: bold;
  }
}
