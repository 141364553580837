.mainPageContainer {
  background-color: transparent;
  padding: 0rem 0rem;
}
.headerSection {
  background-color: transparent;
  height: auto;
  position: relative;
  z-index: 10;
}
.headerSection p {
  line-height: 2rem !important;

  color: #575757;
}

.ourProduts {
  background-image: url("../../../assets/images/our-product-background.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.leftSection {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  background-image: url("../../../assets/images/storage-background.svg");
}
.storageImage {
  width: 80%;
  height: 100%;
}
button:focus {
  outline: none;
}

// h3 {
//   padding-top: 2rem;
// }
@media only screen and (max-width: 1620px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 1rem 6rem;
    position: relative;
    z-index: 10;
  }
  .jumbotron-img {
    img {
      height: 52.5rem;
    }
  }
}
@media only screen and (max-width: 1120px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 2.5rem 4rem;
    position: relative;
    z-index: 10;
  }

  .jumbotron-img {
    img {
      height: 450px;
    }
  }
  .positions-margin {
    margin-top: 0px !important;
  }
}
@media only screen and (max-width: 920px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 2.5rem 1.5rem;
    position: relative;
    z-index: 10;
  }
  .jumbotron-img {
    img {
      height: 300px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 1rem 1rem;
    position: relative;
    z-index: 10;
  }
  .mainPageContainer {
    background-color: white;
    // padding-top: 1rem;
  }

  .jumbotron {
    height: auto !important;
  }

  .jumbotron-mobile {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .jumbotron-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 165px;
    }
  }
  .jumbotron-img {
    display: none;
  }

  .sectionJobDescription ul {
    padding-left: 2rem;
    width: 35ch;
  }
}

@media only screen and (max-width: 320px) {
  .sectionJobDescription ul {
    width: 30ch;
    padding-left: 2rem;
  }

  // h3 {
  //   font-size: 1.3rem;
  // }
}
