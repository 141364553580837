.openPositionSection {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;

  .currentOpeningHeading {
    margin-bottom: 3.5rem;
    text-align: left;

    @media only screen and (max-width: $mobile) {
      margin-bottom: 2.5rem;
    }
  }

  // .CardScrollBar {
  //   overflow-y: scroll;
  // }

  // .CardScrollBar::-webkit-scrollbar {
  //   display: none;
  // }

  // /* Hide scrollbar for IE, Edge and Firefox */
  // .CardScrollBar {
  //   -ms-overflow-style: none; /* IE and Edge */
  //   scrollbar-width: none; /* Firefox */
  // }
}

a {
  color: #707070;
}

a:hover {
  color: $accent;
}

.open-position {
  font-size: 2.5rem;
  color: #023e8a;
}

.description {
  color: #4d4d4d;
  font-size: 24px;
  line-height: 140%;
  width: 694px;
  padding-top: 48px;
}
.position-tabs {
  .nav-tabs {
    margin-top: 20px;
    border: none;
  }

  .nav-item {
    font-size: 1.5rem;
    color: #27323d;
    font-weight: 500;
    margin: 1rem 2rem 1rem 0;
    padding-left: 0;
    padding-right: 0;
  }

  .nav-tabs .nav-link.active {
    border: 1px solid white;
    border-bottom: 4px solid #ff7d1f;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: 1px solid white;
    border-bottom: 4px solid #ff7d1f;
  }

  .tab-content {
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid #b7b7b7;
    border-bottom: 1px solid #b7b7b7;

    .textLabel {
      color: #707070;
      margin: 0;
      font-size: 1.2rem;
      width: 100%;
    }

    .textStyle {
      font-style: italic;
    }

    .mustHaveTitle {
      color: black;
      margin-left: 5px;
      padding: 1rem 0;
      font-size: 1.2rem;
    }

    .mustHaveDescription {
      ul {
        padding: 0;

        li {
          list-style-type: none;
          font-size: 0.98rem;
          margin-left: 5px;
        }
      }
    }

    .readMoreCaret {
      background: white;
      border: none;
      font-size: 1.2rem;
      text-decoration: underline;
      float: right;
      line-height: 22px;

      img {
        float: right;
        width: 12px;
        margin-top: 10px;
        margin-left: 5px;
      }
    }
  }
}
.padd-3 {
  padding: 3rem 0;
}
button:focus {
  outline: none;
}
.mustHaveDescription ul li::before {
  content: "" !important;
}

@media only screen and (max-width: 768px) {
  .position-tabs .nav-item {
    font-size: 1.5rem;
    margin: 1rem 2rem 1rem 0;
  }
  .position-tabs .tab-content {
    margin-top: 2.2rem;
    padding: 1rem 0;
  }
  .position-tabs .tab-content .textLabel {
    font-size: 1.5rem;
  }
  .position-tabs .tab-content .readMoreCaret {
    line-height: 2rem;
  }
  .position-tabs .tab-content .readMoreCaret img {
    margin-top: 13px;
  }
  .position-tabs .tab-content .mustHaveDescription ul li {
    font-size: 1.1rem;
  }
  .textStyle {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 500px) {
  .position-tabs .nav-item {
    font-size: 1.2rem;
    margin: 1rem 1rem 1rem 0;
  }
  .position-tabs .tab-content {
    margin-top: 1rem;
  }
  .position-tabs .tab-content .textLabel {
    font-size: 1.2rem;
  }
  .position-tabs .tab-content .mustHaveDescription ul li {
    font-size: 0.98rem;
  }
  .textStyle {
    font-size: 0.98rem !important;
  }
  .readMoreCaret {
    font-size: 16px;
  }
  .padd-3 {
    padding: 2rem 0;
  }
  .mobile-margin {
    margin: 0 !important;
  }
  .open-position {
    font-size: 2rem;
  }
  .description {
    padding-top: 24px;
  }
}
