.SideBar {
  width: 23rem;
  padding-inline-start: 0;
  position: absolute;
  text-align: left;

  ::before {
    content: none;
  }

  li {
    list-style-type: none;
    color: $cp-new-text-color-card;
    cursor: pointer;
    @extend .is-cp-border;
  }

  .active {
    color: $cp-white;
    background-color: $cp-new-primary;
    border-color: $cp-new-primary;
    clip-path: polygon(
      0 0%,
      10% 0,
      90% 0,
      100% 50%,
      100% 50%,
      90% 100%,
      0% 100%,
      0% 90%,
      0% 0%
    );
  }
}

.positionMenu {
  width: 100%;
  margin-bottom: 2rem;

  .dropdown-toggle {
    background-color: $transparent !important;
    border-color: $text-color-dark !important;
    color: $text-color-dark !important;
    // box-shadow: ;
  }

  .dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .btn {
    width: inherit;
    background-color: $transparent;
    color: $text-color-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-color: $text-color-dark;
  }

  .dropdown-menu {
    width: inherit;
    padding-top: 0;
    padding-bottom: 0;
    a {
      padding: 12px 16px;
      background-color: $accent-white;
    }

    .active {
      color: $cp-white;
      background-color: $accent;
    }
  }
}
