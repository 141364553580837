@import "./variables";
@import "./break-points";
@import "./typography";

body {
  margin: 0;
  color: $dark-text;
  font-family: "Poppins";
  // font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
