.navBar {
  // height: 4.5rem;
  // padding: 1rem 8rem;
  box-shadow: 2px 3px 3px 0px #f8f9fa;
}
.navIcon {
  height: 2.2rem;
  cursor: pointer;
}
.navIcon:hover {
  transform: scale(1.05);
}
.tabText {
  cursor: pointer;
  // transition: transform 0.1s;
  color: $dark-text;
}
.tabText:hover {
  color: $accent;
  // background-color: $accent;
  transform: scale(1.05);
}

.navBarCustom {
  .active {
    color: $accent;
    // background-color: $accent;
    transform: scale(1.05);
  }
}
.mobitTabText {
  padding: 0;
}
button:focus {
  outline: none;
}
.nav-link {
  padding: 2rem 1rem;
}

.menuPadding {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

// @media only screen and (max-width: 1620px) {
//   .navBar {
//     height: 4.5rem;
//   }
// }
// @media only screen and (max-width: 1120px) {
//   .navBar {
//     height: 4.5rem;
//     // padding: 1rem 4rem;
//   }
// }
// @media only screen and (max-width: 920px) {
//   .navBar {
//     height: 4.5rem;
//     // padding: 1rem 1.5rem;
//   }
// }
@media only screen and (max-width: 768px) {
  .navIcon {
    height: 2rem;
    cursor: pointer;
  }
  .navBar {
    // height: 4rem;
    // padding: 0rem 1rem;
  }
  .menuIcon {
    height: 1rem;
    cursor: pointer;
  }
  .menuDrawer {
    width: 100%;
    margin-top: 0.5rem;
    -webkit-box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.75);
  }
  .nav-link {
    padding: 0rem;
  }
}
