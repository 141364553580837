.mainPageContainer {
  background-color: transparent;
  padding: 0rem 0rem;
}
.headerSection {
  background-color: transparent;
  height: auto;

  position: relative;
  z-index: 10;
}
.headerSection p {
  line-height: 2rem !important;
  color: #575757;
}

.apply-here-box {
  border-bottom: 4px solid #ff7d1f;
  width: fit-content;
  padding-bottom: 5px;
  color: #495057;
}

.apply-here-box1 {
  border: 1px solid #b7b7b7;
  display: flex;
  justify-content: space-between;
  color: #3369ce;
}

.ourProduts {
  background-image: url("../../../assets/images/our-product-background.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.leftSection {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  background-image: url("../../../assets/images/storage-background.svg");
}
.storageImage {
  width: 80%;
  height: 100%;
}
button:focus {
  outline: none;
}

.header-bg {
  background: rgba(243, 250, 253, 255);
}

.description {
  color: rgb(33, 37, 41);
}
.apply-btn {
  color: #3369ce;
}

.open-position {
  font-weight: bold;
  color: #212529;
}

@media only screen and (max-width: 142.5rem) {
  .image {
    img {
      height: 300px;
    }
  }

  .nav-items {
    padding-left: 30px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .description {
    padding-bottom: 30px;
    font-size: 1rem;
    width: 50ch;
  }
  .image {
    padding-top: 48px;
    img {
      height: 165px;
    }
  }

  .open-positions-margin {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 865px) {
  .nav-items {
    padding-left: 5px !important;
  }
}

@media only screen and (max-width: 768px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 1rem 1rem;
    position: relative;
    z-index: 10;
  }
  .mainPageContainer {
    background-color: white;
    // padding-top: 1rem;
  }
  .open-position {
    font-size: 1.5rem;
  }
  .description {
    padding-bottom: 24px;
    font-size: 1rem;
    width: 40ch;
  }

  .apply-btn {
    font-size: 0.8rem;
  }

  .image {
    padding-top: 10px;
    img {
      height: 200px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .header-bg {
    flex-direction: column;
  }

  .image {
    padding-top: 10px;
    img {
      height: 200px;
    }
  }

  .description {
    padding-bottom: 24px;
    width: auto;
  }

  .apply-here-box1 {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 320px) {
  .image {
    padding-top: 10px;
    img {
      height: 165px;
    }
  }

  .description {
    padding-bottom: 24px;
    // width: 30ch;
  }

  .open-position {
    font-size: 1.4rem;
  }

  .apply-btn {
    font-size: 0.77rem;
  }

  .apply-here-box1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-size: 0.77rem;
  }
}

.OpenPositionPage {
  overflow: scroll;
  text-align: -webkit-right;

  @media only screen and (min-width: $tablet) {
    margin-bottom: 2.5rem;
  }
}

.OpenPositionPage::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.OpenPositionPage {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
