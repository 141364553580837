.Career {
  .careerPageTitle {
    padding-top: 0.5rem;
    padding-bottom: 3.5rem;
  }

  .CareerApplySection {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @media only screen and (max-width: $mobile) {
      .is-cp-h4-heading {
        text-align: center;
      }
    }
  }
}
