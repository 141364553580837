.CTA {
  img {
    // width: 1.063rem;
    height: 1.375rem;

    @media only screen and (max-width: $mobile) {
      //   width: 0.708rem;
      height: 0.917rem;
    }
  }

  .IconPadding {
    padding-left: 0.375rem;
    @media only screen and (max-width: $mobile) {
      padding-left: 0.25rem;
    }
  }
}
