button:focus {
  outline: none;
}
.footerBox {
  padding: 5rem 0rem;
  background-color: #27323d;
}

.footerMiddleDiv {
  padding-left: 7rem;
}

.anchorDesign {
  background-color: transparent;
  text-decoration: none;
  color: $cp-white;
}
.anchorDesign:hover {
  color: $accent;
  text-decoration: none;
}
@media only screen and (max-width: 1500px) {
  .footerMiddleDiv {
    padding-left: 3rem;
  }
}
@media only screen and (max-width: 1120px) {
  .footerBox {
    padding: 0.5rem 0rem;
    background-color: #27323d;
  }
  .footerMiddleDiv {
    padding-left: 4rem;
  }
}
@media only screen and (max-width: 920px) {
  .footerBox {
    padding: 0.5rem 0rem;
    background-color: #27323d;
  }
  .footerMiddleDiv {
    padding-left: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .footerBox {
    padding: 2rem 0rem;
    background-color: #27323d;
  }
  .footerMiddleDiv {
    padding-left: 12px !important;
  }
}
