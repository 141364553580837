.card {
  border: none;
  -webkit-box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.27);
  margin-bottom: 30px;
}
.card-img-top {
  width: 30%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  margin: auto;
  margin-top: 10%;
}
.card-title {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 300;
  color: #707070;
}
.card-subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  color: #707070;
  margin: 18px;
  font-style: italic;
  text-align: center;
}
.card-text {
  font-size: 0.98rem;
  font-weight: 300;
  text-align: center;
  height: 225px;
  overflow: auto;
}
.link_icon {
  width: 35px;
  position: absolute;
  right: 2%;
  top: 2%;
}
button:focus {
  outline: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 10px;
}​
::-webkit-scrollbar-button {
  background-color: #fff;
}
::-webkit-scrollbar-track-piece {
  background-color: white;
}
   
@media only screen and (max-width: 500px) {
    .mobile-padd {
        padding: 0 !important;
    }
    .text-center-in-mobile {
      text-align: center;
    }
}
