.mainHeader{
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    

    .socialMedia_icons{
        display: flex;
    }
}

