$cp-brand: #5bc3de !default;
$cp-white: #ffffff !default;
$cp-black: hsl(0, 0%, 0%) !default;
$accent: #00a7db !default;
$accent-white: #eef9fc !default;
$accent-black: #000000 !default;
$failed: #cc3300 !default;
$warning: #ff8f39 !default;
$success: #339900 !default;
$transparent: transparent;
$white-smoke: #f0f0f0;
$dark-text: #444444;
$disabled: #b3b3b3;
$night-rider: #333333;
$required: #ff725e;

// introduce as per new design discussion
$cp-new-primary: #2aabcb;
$cp-new-text-color-card: #666666;
$cp-new-background-color: #e0e0e0;

$cp-fade-color: #ccc;
