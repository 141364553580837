.position-tabs {

  .nav-tabs {
    margin-top: 20px;
    border: none;
  }

  .nav-item {
    font-size: 2rem;
    color: #27323d;
    font-weight: 500;  
    margin: 1rem 6rem 1rem 0;
    padding-left: 0;
    padding-right: 0;
  }

  .nav-tabs .nav-link.active {
    border: 1px solid white;
    border-bottom: 0.25rem solid #ff7d1f;
  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border: 1px solid white;
    border-bottom: 0.25rem solid #ff7d1f;
  }

  .tab-content {
    margin-top: 2.2rem;
    padding: 2rem 0;
    border-top: 1px solid #b7b7b7;
    border-bottom: 1px solid #b7b7b7;

    .textLabel {
      color: #707070;
      margin: 0;
      font-size: 1.8rem;
      width: 100%;
    }

    .textStyle {
      font-style: italic;
    }

    .mustHaveTitle {
      color: black;
      margin-left: 5px;
      padding: 1.5rem 0;
    }

    .mustHaveDescription {
      ul {
        padding: 0;

        li {
          list-style-type: none;
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
          margin-left: 5px;
        }
      }
    }

    .readMoreCaret {
      background: white;
      border: none;
      font-size: 20px;
      text-decoration: underline;
      float: right;
      line-height: 3rem;

      img {
        float: right;
        width: 16px;
        margin-top: 20px;
        margin-left: 5px;
      }
    }
  }
}
button:focus {
  outline: none;
}
@media only screen and (max-width: 768px) {
  .position-tabs .nav-item {
    font-size: 1.5rem;
    margin: 1rem 2rem 1rem 0;
  } 
  .position-tabs .tab-content {
    margin-top: 2.2rem;
    padding: 1rem 0;
  }
  .position-tabs .tab-content .textLabel {
    font-size: 1.5rem;
  }
  .position-tabs .tab-content .readMoreCaret {
    line-height: 2rem;
  }
  .position-tabs .tab-content .readMoreCaret img {
    margin-top: 13px;
  }
  .position-tabs .tab-content .mustHaveDescription ul li {
    font-size: 1.1rem;
  } 
  .textStyle {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 500px) {
  .position-tabs .nav-item {
    font-size: 1.3rem;
    margin: 1rem 1.2rem 1rem 0;
  } 
  .position-tabs .tab-content {
    margin-top: 1rem;
  }
  .position-tabs .tab-content .textLabel {
    font-size: 1.2rem;
  }
  .position-tabs .tab-content .mustHaveDescription ul li {
    font-size: 0.98rem;
  } 
  .textStyle {
    font-size: 0.98rem !important;
  }
  .readMoreCaret {
    font-size: 16px;
  }
}