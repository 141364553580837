.rightBackImage {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-image: url("../../../assets//images/banner-background.svg");
}
.bannerLogo {
  width: 100%;
  height: 645px;
}
.bannerSubLogo {
  width: 60px;
  display: block;
}
.headerTitle {
  font-size: 2.5rem;
  font-weight: bold;
}
// .bannerSubTitle {
//     font-size: 0.98rem;
//     color: #747474;
//     padding: 12px 0;
//     // margin-bottom: 10px;
// }
button:focus {
  outline: none;
}
.pl-20 {
  padding-left: 30px;
  list-style: none;
}
.max-width-90 {
  max-width: 90%;
}
ul li::before {
  content: "\2022";
  color: #f67d21;
  font-weight: bold;
  width: 1em;
  margin-left: -1em;
  font-size: 2rem;
  line-height: 22px;
  float: left;
}
@media only screen and (max-width: 1400px) {
  .bannerLogo {
    height: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .headerTitle {
    font-size: 2.8rem;
    font-weight: bold;
  }
}
@media only screen and (max-width: 768px) {
  .bannerLogo {
    width: 50%;
    height: 100%;
  }
  .headerTitle {
    font-size: 2.1rem;
    font-weight: bold;
  }
  .bannerSubLogo {
    margin: auto;
  }
  .max-width-90 {
    max-width: 100%;
  }
}
