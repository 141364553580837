//import globar scss

.applicationForm {
  .FormSection {
    padding-bottom: 2.5rem;
  }
}
.form-control::placeholder {
  color: $cp-fade-color;
}
.modal-content {
  margin-top: 10rem;
  border-radius: 0;
  border: 1px solid white;

  .modal-header {
    border-bottom: 0;
  }

  .close {
    padding: 15px 30px;

    span {
      font-size: 3rem;
      font-weight: 300;
    }
  }

  .modal-body {
    padding: 1rem 2rem 2rem;

    .modal-title {
      text-align: center;
    }

    .modal-subtitle {
      font-weight: 300;
      margin-bottom: 5%;
      font-size: 1.2rem;
    }

    // .modal-label {
    //   font-size: 20px;
    //   font-weight: 300;
    //   color: #272626;
    // }

    .checkbox {
      vertical-align: middle;
    }

    .modal-input {
      border: none;
      border-bottom: 1px solid #aaaaaa;
      border-radius: 0;
      font-size: 1rem;
      padding: 0;
      font-weight: 300;
      color: #707070;
      margin-bottom: 2rem;

      &:focus {
        background-color: unset;
        border-color: unset;
        box-shadow: none;
      }
    }

    .radio-options {
      margin-right: 10px;
      height: 15px;
    }

    .modal-textarea {
      margin-bottom: 3rem;

      &:focus {
        background-color: unset;
        border-color: unset;
        box-shadow: none;
      }
    }

    .form-check-label {
      font-size: 15px;
      color: #707070;
      font-weight: 300;
      margin-left: 10px;
      margin-top: 10px;
    }

    .form-check-input {
      margin-top: 12px;
      height: 20px;
      width: 20px;
    }
  }
}

.mb-30 {
  margin-bottom: 30px;
}

button:focus {
  outline: none;
}
.error {
  border: none;
  border-bottom: 1px solid red;
  border-radius: 0;
  font-size: 1rem;
  padding: 0;
  font-weight: 300;
  color: red;

  &:focus {
    background-color: unset;
    border-color: unset;
    box-shadow: none;
  }
}
.alertHeading {
  padding-left: 20px;
  font-size: 2rem;
  font-weight: 700;
}
.alertPara {
  padding-left: 20px;
}

.required {
  color: red;
}

.requiredfield {
  color: red;
  margin-bottom: 30px;
}
.errorMessage {
  margin-bottom: 20px;
  color: red;
}
.errorMessageBox {
  color: red;
}
.tech-stack {
  color: #3f3f3f;
}

@media only screen and (max-width: 768px) {
  .modal-content .modal-body {
    padding: 1rem 2rem 1rem;
  }
}
