.contactForm {
  .modal-content {
    margin-top: 1.5rem;
    border-radius: 0;
    border: 1px solid white;

    .modal-header {
      border-bottom: 0;
    }

    .close {
      padding: 15px 30px;

      span {
        font-size: 3rem;
        font-weight: 300;
      }
    }

    .modal-body {
      padding: 1rem 2rem 2rem;

      .modal-title {
        text-align: center;
      }

      .modal-label.requiredField::after {
        content: " *";
        color: red;
      }

      .modal-input {
        border: none;
        border-bottom: 1px solid #aaaaaa;
        border-radius: 0;
        font-size: 1rem;
        padding: 0;
        font-weight: 300;
        color: #707070;
        margin-bottom: 0rem;

        &:focus {
          background-color: unset;
          border-color: unset;
          box-shadow: none;
        }
      }

      .modal-textarea {
        margin-bottom: 0rem;

        &:focus {
          background-color: unset;
          border-color: unset;
          box-shadow: none;
        }
      }

      .form-check-label {
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1.125rem;
        letter-spacing: 0.031rem;
        text-align: left;
        margin-left: 15px;
      }

      .form-check-input {
        margin-top: 9px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .checkbox {
    vertical-align: middle;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  button:focus {
    outline: none;
  }

  @media only screen and (max-width: 768px) {
    .modal-content .modal-body {
      padding: 1rem 2rem 1rem;
    }
  }
}