.jobDescriptionSection {
  .EligibilitySection {
    padding-bottom: 1.75rem;
    padding-top: 1.75rem;

    @media screen and (max-width: $mobile) {
      padding-bottom: 1rem;
    }
  }

  img {
    padding-right: 0.875rem;
    font-size: 1.25rem;
    align-self: baseline;
    margin-top: 0.313rem;
  }

  ol {
    li {
      list-style-type: none;
    }
  }
  .is-cp-body-text-1 {
    line-height: 32px;
  }

  .AboutUs {
    p {
      line-height: 2rem;
    }
  }

  .JobResponsibilitySection {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    .JobResponsibilitySectionHeading {
      padding-bottom: 1.75rem;
      padding-top: 0;
    }

    .JobResponsibilitySectionContent {
      display: flex;
      justify-content: space-between;

      .JobResponsibilitySectionSkills {
        ol {
          padding-inline-start: 20px;
        }

        @media only screen and (max-width: $mobile) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      @media only screen and (max-width: $mobile) {
        flex-wrap: wrap;
      }
    }
  }

  .PerksAndBenefits {
    padding: 1.75rem 0;

    .PerksAndBenefitsHeading {
      padding-bottom: 1.75rem;
    }
    @media only screen and (max-width: $mobile) {
      padding: 0 0;
    }
  }

  .WorkHereSection {
    padding: 1.75rem 0;
  }
  h4 {
    padding: 1.25rem 0 0.5rem;

    @media only screen and (max-width: $tablet) {
      padding: 2.25rem 0 0.75rem;
    }
  }
  .SkillsHeading {
    border-bottom: 0.25rem solid $accent;
    width: fit-content;
    margin-bottom: 1rem;
  }

  ul li::before {
    display: none;
  }

  // button{
  //     margin: 2.25rem 0 3.5rem;
  //     @media only screen and (max-width: $tablet) {
  //       padding:1.25rem 0 2.125rem;

  //     }
  // }

  .apply_cta {
    padding-top: 2rem;
  }

  .apply_btn {
    margin: 2.25rem 0 3.5rem;
    @media only screen and (max-width: $tablet) {
      padding: 1.25rem 0 2.125rem;
      text-align: center;
    }
  }
}
