.techBox {
    position: relative;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;    
    width: 55%;
    // padding: 10%;
    -webkit-box-shadow: 0px 1px 6px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 6px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 6px -3px rgba(0,0,0,0.75);
    background-color: #fff;
  
}
.techBox img {
   width: 60%;
    height: 60%;
   object-fit:"contain"
}
.blueSection {
    padding: 0rem 6rem;
}
.techBox:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
.techBoxPart {
width: 14.285714285714285714285714285714%;
*width: 14.285714285714285714285714285714%;
}
button:focus {
    outline: none;
}
@media only screen and (max-width: 1620px) {
    .blueSection {
        padding: 0rem 8rem;
    }
    
}
@media only screen and (max-width: 1120px) {
    .blueSection {
        padding: 0rem 3rem;
    }
   
}
@media only screen and (max-width: 920px) {
    .blueSection {
        padding: 0rem 1.5rem;
    }
   
}
@media only screen and (max-width: 768px) {
    .blueSection {
        padding: 0rem 1rem;
    }
    .techBoxPart {
        width: 20%;
        *width: 20%;
    }
}
