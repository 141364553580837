.mainPageContainer {
  background-color: white;
  padding: 0rem 0rem;
}
.headerSection {
  background-color: transparent;
  height: auto;
  // padding: 2.5rem 8rem;
  position: relative;
  z-index: 10;
}
.headerSection p {
  line-height: 2rem !important;
  color: #575757;
}

.ourProduts {
  background-image: url("../../../assets/images/our-product-background.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.leftSection {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  background-image: url("../../../assets/images/storage-background.svg");
}
.storageImage {
  width: 80%;
  height: 100%;
}
button:focus {
  outline: none;
}
@media only screen and (max-width: 1620px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 1rem 6rem;
    position: relative;
    z-index: 10;
  }
}
@media only screen and (max-width: 1120px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 2.5rem 4rem;
    position: relative;
    z-index: 10;
  }
}
@media only screen and (max-width: 920px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 2.5rem 1.5rem;
    position: relative;
    z-index: 10;
  }
}

@media only screen and (max-width: 768px) {
  .headerSection {
    background-color: transparent;
    height: auto;
    // padding: 1rem 1rem;
    position: relative;
    z-index: 10;
  }
  .mainPageContainer {
    background-color: #fff;
    // padding-top: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .mobile-padd {
    padding: 0 !important;
  }
}
