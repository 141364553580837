.is-cp-h1-heading {
  font-size: 4rem;
  font-weight: 600;
  line-height: 4.5rem;
  letter-spacing: 0.031rem;

  @media only screen and (max-width: $mobile) {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }
}

.is-cp-h2-heading {
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;
  letter-spacing: 0.031rem;

  @media only screen and (max-width: $mobile) {
    font-size: 2rem;
    line-height: 3rem;
  }
}
.is-cp-h3-heading {
  //styleName: Desktop/Heading/H3;

  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  letter-spacing: 0.031rem;
  text-align: left;

  @media only screen and (max-width: $mobile) {
    //styleName: Mobile/Heading/H3;

    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    letter-spacing: 0.031rem;
    text-align: left;
  }
}

.is-cp-h4-heading {
  //styleName: Desktop/Heading/H4;

  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.031rem;
  text-align: left;

  @media only screen and (max-width: $mobile) {
    //styleName: Mobile/Heading/H4;

    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    letter-spacing: 0.031rem;
    text-align: left;
  }
}

// .is-cp-h5-heading {
//   //styleName: Desktop/Heading/H5;

//   font-size: 1.25rem;
//   font-weight: 600;
//   line-height: 2rem;
//   letter-spacing: 0.031rem;
//   text-align: left;

//   @media only screen and (max-width: $mobile) {
//     //styleName: Mobile/Heading/H5;
//     font-size: 1rem;
//     line-height: 1.5rem;
//   }
// }

.is-cp-h5-heading {
  //styleName: Desktop/Heading/H5;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.031rem;

  @media only screen and (max-width: $mobile) {
    //styleName: Mobile/Heading/H5;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

.is-cp-h6-heading {
  //styleName: Desktop/Heading/H6;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  text-align: left;
}

.is-cp-body-text-1 {
  //styleName: Desktop/Body Text/B1;

  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  text-align: left;

  @media only screen and (max-width: $mobile) {
    //styleName: Mobile/Body Text/B1;

    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: 0.031rem;
    text-align: left;
  }
}
.is-cp-body-text-2 {
  //styleName: Desktop/Body Text/B2;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.031rem;
  text-align: left;

  @media only screen and (max-width: $mobile) {
    //styleName: Mobile/Body Text/B2;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
    letter-spacing: 0.031rem;
    text-align: left;
  }
}

.is-cp-body-text-3 {
  //styleName: Mobile/Body Text/B3;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.68rem;
  letter-spacing: 0.031rem;
  @media only screen and (max-width: $mobile) {
    //styleName: Mobile/Body Text/B3;
    font-size: 1rem;
    line-height: 1.5rem;
    // text-align: left;
  }
}

.is-cp-body-text-custom {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.5;
}

.is-cp-label-text {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.125rem;
  letter-spacing: 0.031rem;
  text-align: left;

  @media only screen and (max-width: $mobile) {
    //styleName: Mobile/Body Text/Label Text;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.125rem;
    letter-spacing: 0.031rem;
    text-align: left;
  }
}

.is-cp-primary-button {
  //styleName: Desktop/Button/Text;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  text-align: center;
  color: $cp-white;
  background-color: $accent;
  border-radius: 2.5rem;
  border: $accent;
  padding: 1rem 2rem;
  width: fit-content;

  @media only screen and (max-width: $mobile) {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.031rem;
    text-align: center;
    color: $cp-white;
    background-color: $accent;
    border-color: $accent;
    padding: 0.75rem 1.5rem;
    width: 100%;
  }
}

.is-cp-primary-button:hover,
.is-cp-primary-small-button:hover {
  background-color: $accent;
  border-color: $accent;
}

.is-cp-primary-small-button {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.031rem;
  text-align: center;
  color: $cp-white;
  background-color: $accent;
  border-color: $accent;
  border-radius: 2.5rem;
  padding: 0.75rem 1.5rem;
  width: fit-content;
}

.is-cp-secondary-button {
  //styleName: Desktop/Button/Text;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  text-align: center;
  border-radius: 2.5rem;
  padding: 1rem 2rem;
  width: fit-content;
  background-color: $transparent;
  color: $accent;
  border-color: $accent;

  @media only screen and (max-width: $mobile) {
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 2.5rem;
    line-height: 1.25rem;
    letter-spacing: 0.031rem;
    text-align: center;
    width: 100%;
    padding: 0.75rem 1.5rem;
  }
}

.is-cp-secondary-small-button {
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 2.5rem;
  line-height: 1.25rem;
  letter-spacing: 0.031rem;
  text-align: center;
  width: fit-content;
  border-radius: 2.5rem;
  padding: 0.75rem 1.5rem;
  background-color: $transparent;
  color: $accent;
  border-color: $accent;
}

.is-cp-secondary-button:hover,
.is-cp-secondary-small-button:hover {
  background-color: $accent;
  border-color: $accent;
}

.is-cp-primary-button.disabled,
.is-cp-primary-button:disabled,
.is-cp-primary-small-button.disabled,
.is-cp-primary-small-button:disabled {
  color: $disabled;
  background-color: $transparent;
  border: 1px solid $disabled;
}

.is-cp-secondary-button.disabled,
.is-cp-secondary-button:disabled,
.is-cp-secondary-small-button.disabled,
.is-cp-secondary-small-button:disabled {
  color: $disabled;
  background-color: $transparent;
  border-color: $disabled;
}

.is-cp-tertiary-button {
  //styleName: Desktop/Button/Text;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  text-align: center;
  width: fit-content;
  padding: 0.5rem;
  border: 0;
  border-bottom: 1px solid black;
  background-color: $transparent;
  @media only screen and (max-width: $mobile) {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.031rem;
    text-align: center;
    padding: 0.5rem;
  }
}

.is-cp-primary-color {
  color: $accent;
}

.is-cp-dark-color {
  color: $text-color-dark;
}

.is-cp-light-color {
  color: $text-color-light;
}

.is-background {
  background-color: $white-smoke;
}

.is-background-gradient {
  background: linear-gradient(
    90deg,
    rgba(42, 175, 203, 0.2) 0%,
    rgba(42, 177, 203, 0.6) 44.2%,
    rgba(42, 173, 203, 0.8) 100%
  );
}
.is-background-primary {
  background-color: $accent-white;
}

.is-cp-background-primary {
  background-color: $cp-new-primary;
}

.is-required {
  color: $required;
}

.is-box-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.is-flex-start {
  display: flex;
  align-items: flex-start;
}

.container {
  max-width: 101.813rem;
  // max-width: 1920px;
  // @media only screen and (max-width: 1920px) {
  //   max-width: 1224px;
  // }
  // @media only screen and (max-width: 1620px) {
  //   max-width: 89.25rem;
  // }

  // @media only screen and (max-width: 142.5rem) {
  //   max-width: 70.5rem;
  // }

  // @media only screen and (max-width: 1120px) {
  //   max-width: 62rem;
  // }

  // @media only screen and (max-width: 920px) {
  //   max-width: 54.5rem;
  // }
  @media only screen and (max-width: 1920px) and (min-width: 1440px) {
    max-width: 1224px; //1144px
  }
  @media only screen and (max-width: 1440px) and (min-width: 1280px) {
    max-width: 1128px; //
  }
  @media only screen and (max-width: 1280px) and (min-width: 768px) {
    max-width: 62rem;
  }
  @media only screen and (max-width: 768px) and (min-width: 600px) {
    max-width: 54.5rem;
  }
  // @media only screen and (max-width: 600px) and (min-width: 320px) {
  //   max-width: auto;
  // }
}

.headerSection {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media only screen and (max-width: 1920px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media only screen and (max-width: 142.5rem) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @media only screen and (max-width: 1280px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.form-input {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.031rem;
  text-align: left;
  padding: 1rem 1.25rem;
}

.sectionPadding {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media only screen and (max-width: $mobile) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.specialCharacter {
  font-weight: 600;
}

.heading-style {
  color: $accent;
}

.is-cp-border {
  border: 0.031rem solid $cp-fade-color;
  border-radius: 0.5rem;
}
